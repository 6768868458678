.coupon-card {
    background-color: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    position: relative;
    max-width: 500px;
  }
  
  .coupon-icon {
    font-size: 1.5rem;
    color: #ff6b6b; /* Light red for the ticket icon */
  }
  
  .coupon-code {
    font-weight: bold;
    color: #000;
  }
  
  .coupon-decor {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .coin-icon {
    font-size: 1.5rem;
    color: #ffc107; /* Golden color for the coin icon */
  }
  
  .coupon-decor img {
    opacity: 0.2; /* Faded decorative icon */
    margin-left: 8px; /* Small margin between icons */
  }
  