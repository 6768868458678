/* Dashboard Navbar Styles */
.DashboardNav .bottom-navbar {
    background-color: #fff;
    border-top: 2px solid #e2e8f0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .DashboardNav  .status-bar {
    background-color: #f1f5f9;
    padding: 8px 0;
  }
  
  .DashboardNav  .status-text {
    display: flex;
    align-items: center;
    font-size: 14px;
  }
  
  .DashboardNav  .status-text .icon {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
  
  .DashboardNav  .nav-items {
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
  }
  
  .DashboardNav  .nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    border-radius: 8px;
    transition: all 0.3s ease;
    color: #6b7280;
  }
  
  .DashboardNav  .nav-item:hover {
    background-color: #f3f4f6;
  }
  
  .DashboardNav  .nav-item.active {
    color: #2563eb; /* Blue for Seller */
  }
  
  .DashboardNav  .nav-icon {
    width: 24px;
    height: 24px;
    transition: transform 0.3s ease;
  }
  
  .DashboardNav  .nav-icon.icon-active {
    transform: scale(1.2);
  }
  
  .DashboardNav  .nav-label {
    margin-top: 6px;
    font-size: 12px;
    font-weight: 500;
    transition: opacity 0.3s ease;
  }
  
  .DashboardNav  .active-indicator {
    height: 3px;
    width: 0;
    transition: width 0.3s ease;
    background-color: #2563eb; /* Blue for Seller */
    margin-top: 4px;
  }
  
  .DashboardNav  .active-indicator-visible {
    width: 30px;
  }
  
  .DashboardNav  .badge {
    width: 18px;
    height: 18px;
    background-color: #ef4444; /* Red for urgent task */
    color: white;
    border-radius: 50%;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .DashboardNav .urgent-indicator {
    width: 10px;
    height: 10px;
    background-color: #ef4444; /* Red for urgent task */
    border-radius: 50%;
    border: 2px solid white;
    animation: pulse 1s infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 0.8;
    }
    50% {
      transform: scale(1.3);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0.8;
    }
  }
  
  /* Active Color Schemes */
  .DashboardNav  .text-primary {
    color: #2563eb;
  }
  
  .DashboardNav  .text-success {
    color: #10b981;
  }
  
  

  