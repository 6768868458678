/* Review.css */
.review-container {
    max-width: 100%;
  }
  
  .order-item-row {
    padding: 10px 0;
  }
  
  @media (max-width: 768px) {
    .order-item-row .col {
      text-align: center;
    }
  }
  
  img.img-fluid {
    max-width: 100%;
    height: auto;
  }
  
  @media (max-width: 576px) {
    .order-item-row .col-3 {
      width: 50%;
    }
  }
  