.bottom-navbar {
    background-color: #333!important;
    border-top: 1px solid #555;
  }
  
  .bottom-navbar .nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
   
  .bottom-navbar .nav-item:hover i {
    transform: scale(1.2); /* Scale icon on hover */
    color: #007bff; /* Change color on hover */
  }
  
  .bottom-navbar .nav-item small {
    font-size: 12px;
    margin-top: 4px;
  }
  .bottom-navbar .nav-item i {
    font-size: 24px;
    transition: transform 0.3s ease, color 0.3s ease; /* Add transition for animation */
  }  
  .bottom-navbar .nav-item:hover {
    background-color: #444;
    border-radius: 10px!important;
  }
  
  @media (max-width: 768px) {
    .bottom-navbar {
      padding: 10px 0;
    }
  
    .bottom-navbar .nav-item i {
      font-size: 26px;
    }
  
    .bottom-navbar .nav-item small {
      font-size: 14px;
    }
  }
  