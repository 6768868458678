/* BottomBar.css */

/* Ensure the bottom bar stays fixed at the bottom */
.navbar.fixed-bottom {
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  }
  
  /* Center icons and text */
  .navbar-brand {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #555;
    text-decoration: none;
    font-size: 14px;
  }
  
  /* Add spacing between icon and text */
  .navbar-brand i {
    margin-bottom: 4px;
  }
  
  /* Active link styling */
  .navbar-brand.active {
    color: #007bff;
  }
  
  /* Hover effect */
  .navbar-brand:hover {
    color: #007bff;
  }